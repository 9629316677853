import { Component, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '../../../common/modal/modal.service';
@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {

  public zoomVideoImage = './assets/img/zoomvideo.svg';

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: ModalService,
  ) { }

  ngOnInit() {

  }


  dismissModal() {
    this.activeModal.dismiss('DISMISSED !');
    this.activeModal.close();
  }

  closeModal() {
    this.activeModal.close();
  }

  expandModel() {
    const parentElement = document.querySelector('.my-inspection-module') as HTMLElement;
    const modalContent = document.querySelector('.modal-content') as HTMLElement;
    const modalSm = document.querySelector('.modal-sm') as HTMLElement;
    const zoomModelWindow = document.querySelector('.zoomModelWindow') as HTMLElement;

    let maximumWidth: any = parentElement.clientWidth - 10;
    maximumWidth = '1305px'; // `${maximumWidth.toString()}px`;
    const standardWidth = '445px';

    if (!zoomModelWindow.classList.contains('expandZoomModel')) {
      zoomModelWindow.classList.add('expandZoomModel');
      zoomModelWindow.style.width = maximumWidth;
      modalContent.style.width = maximumWidth;
      modalSm.style.width = maximumWidth;
    } else {
      zoomModelWindow.classList.remove('expandZoomModel');
      zoomModelWindow.style.width = standardWidth;
      modalContent.style.width = standardWidth;
      modalSm.style.width = standardWidth;
    }
  }
}
